import Entity from '../entity'
import Address from '../students/address';
 
export default class Lawyer extends Entity<number>{
    document_type_id: number;
    address_id: number;
    address_legal_id: number;
    address_work_id: number;
    condition_id: number;
    code: string;
    document: string;
    firstname: string;
    lastname1: string;
    lastname2: string;
    email: string;
    phone_mobile: string;
    condition_message: string;
    registration_date: string;
    last_payment: string;
    expiration_date: string;
    death_date: string;
    alive: boolean;
    active: boolean;
    avatar: string;
    password: string;
    
    address: Address;
    address_legal: Address;
    address_work: Address;
}


