import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Trade from '../../entities/registryoffices/trade'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface TradeState extends ListState<Trade>{
    editModel: Trade;
    uploading: boolean;
}
class TradeModule extends ListModule<TradeState,any,Trade>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Trade>(),
        loading: false,
        uploading: false,
        editModel: new Trade(),
        activeList: new Array<Trade>(),
        path: 'lawyer/trades'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async uploadForm(context: ActionContext<TradeState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.post('/api/lawyer/trades/upload-form', payload.data).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const tradeModule=new TradeModule();
export default tradeModule;
