














































import { Component, Emit, Inject, Prop, Watch } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import Util from '@/lib/util';

@Component({
    components: {  }
})

export default class Account extends AbpBase {
    loading: boolean = false;
    items: Array<any> = [
        {
            icon: 'account_circle',
            //href: '#',
            name: 'profile',
            title: 'Perfil',
            click: () => { }
        },
        {
            title: 'divider',
            click: () => { }
        },
        {
            icon: 'exit_to_app',
            href: '#',
            title: 'Cerrar Sesion',
            click: async (e) => {
                await this.logout();
            }
        }
    ];

    get logged() {
        return this.$store.state.session.logged;
    }

    get user() {
        return this.$store.state.session.user;
    }

    get userName() {
        return this.$store.state.session.user ? this.$store.state.session.user.firstname : ''
    }


    async logout() {
        this.loading = true;
        await this.$store.dispatch({ type: 'session/logout' });
        this.loading = false;
        this.$store.commit('app/logout', this);
        Util.abp.auth.clearToken();
        location.reload();
    }

    async mounted() {
        
    }
}
