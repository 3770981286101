import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import User from '../../entities/admin/user'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface UserState extends ListState<User> {
    editModel: User;

}
class UserModule extends ListModule<UserState, any, User>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<User>(),
        loading: false,
        editModel: new User(),
        activeList: new Array<User>(),
        path: 'users'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const userModule = new UserModule();
export default userModule;

