import Entity from '../entity'

export default class Course extends Entity<number>{
    code: string;
    name: string;
    description: string;
    duration_type: string;
    duration: number;
    active: boolean;

    image_url: string;
    imageData: string;
}


