






















import TopMenu from '../components/top-menu.vue';
import Account from '../components/account.vue';
import util from '../lib/util';
import AbpBase from '../lib/abpbase'
import AppConst from '../lib/appconst'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    components: { TopMenu, Account } 
})

export default class AppToolbar extends AbpBase {
    name: string = 'app-toolbar';
    get shrink() {
        return this.$store.state.app.shrink;
    }
    get userName() {
        return this.$store.state.session.user ? this.$store.state.session.user.full_name : ''
    }
    get currentPath() {
        //return this.$store.state.app.currentPath;
        let breadcrumbs = [];
        this.$store.state.app.currentPath.forEach(item => {
            if (item.meta.title != '') {
                breadcrumbs.push({
                    text: item.meta.title,
                    href: '#' + item.path
                });
            }
        });
        return breadcrumbs;
    }
    get title() {
        let text = '';
        this.$store.state.app.currentPath.forEach(item => {
            text = item.meta.title;
        });
        return text;
    }
    get companyName() {
        if (this.$store.state.session.company == undefined) return AppConst.setting.appName;
        return this.$store.state.session.company.name;
    }
    items: Array<any> = [
            {
                icon: 'account_circle',
                name: 'profile',
                title: 'Perfil',
                click: (e) => {
                    util.openNewPage(this, 'ownspace_index', null, null);
                    this.$router.push({
                        name: 'ownspace_index'
                    });
                }
            },
            {
                icon: 'settings',
                name: 'settings',
                title: 'Configuracion',
                click: (e) => {
                    
                }
            },
            {
                icon: 'exit_to_app',
                href: '#',
                title: this.L('Cerrar Sesion'),
                click: (e) => {
                    this.$store.commit('app/logout', this);
                    util.abp.auth.clearToken();
                    location.reload();
                }
            }
    ];
    @Emit('toggle-click')
    toggleClick() {
        this.$store.commit('app/toggleShrink');
    }
} 
