import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Course from '../../entities/courses/course'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'

interface CourseState extends ListState<Course>{
    editModel:Course;
}
class CourseModule extends ListModule<CourseState,any,Course>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Course>(),
        loading:false,
        editModel:new Course(),
        activeList: new Array<Course>(),
        images: new Array<any>(),
        path: 'courses',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getInfo(context: ActionContext<CourseState, any>, payload: any) {
            let reponse = await Ajax.get('/api/courses/get-info/' + payload.id);
            return reponse.data.result;
        },
        async getLastCode(context: ActionContext<CourseState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/courses/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const courseModule=new CourseModule();
export default courseModule;
