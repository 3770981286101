












import util from '../lib/util';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
@Component({
    components: {},
    data: () => ({
    }),
})
export default class TopMenu extends Vue {
    name: string = 'topMenu';
    @Prop() shrink: boolean;
    @Prop({ type: Array }) menuList: Array<any>;
    @Prop({
        validator: (val) => { return util.oneOf(val, ['dark', 'light']); }
    }) theme: string;
    menuItems: Array<any> = [
        { title: 'Mi Perfil', path: 'profile', icon: 'person' },
        { title: 'Votacion', path: 'vote', icon: 'school' },
        { title: 'Solicitud 25%', path: 'application', icon: 'book_online' },
        { title: 'Mesa de Partes', path: 'trade', icon: 'account_balance' },
    ]

}
