import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Lesson from '../../entities/courses/lesson'
import Util from '../../../lib/util'
import Ajax from '../../../lib/ajax'

interface LessonState extends ListState<Lesson>{
    editModel: Lesson;
    loadingAction: boolean,
}
class LessonModule extends ListModule<LessonState, any, Lesson>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Lesson>(),
        activeList: new Array<Lesson>(),
        loading: false,
        loadingAction: false,
        editModel: new Lesson(),
        path: 'lessons',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const lessonModule=new LessonModule();
export default lessonModule;
