























































import { Component, Vue, Inject, Prop, Emit } from 'vue-property-decorator';
import AbpBase from '../../../lib/abpbase'
@Component({
    data: vm => ({            
    }), 
})
export default class SidebarMenu extends AbpBase {
    name: string = 'sidebarMenu';
    @Prop({ type: Array }) menuList: Array<any>;
    @Prop({ type: Number }) iconSize: number;
    @Prop({ type: String, default: 'dark' }) menuTheme: string;
    @Prop({ type: Array }) openNames: Array<string>;
    itemTitle(item: any): string {
        return this.L(item.meta.title);
    }
    genChildTarget(item, subItem) {
        if (subItem.path) {
            return {
                name: subItem.path,
            };
        }
        return { name: `${item.path}/${(subItem.path)}` };
    }
    @Emit('on-change')
    changeMenu(active: string) {
        //console.log("SidebarMenu - " + active);
    }
    updated() {
        this.$nextTick(() => {
            //if (this.$refs.sideMenu) {
            //    (this.$refs.sideMenu as any).updateActiveName();
            //}
        });
    }

}
