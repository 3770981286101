declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router { 
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any; 
        children?: Array<Router>;
    } 
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import login from '../views/login.vue'
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';

export const loginRouter: Router = {
    path: '/',
    name: 'login',
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    permission: '',
    meta: { title: '' },
    component: main,
    children: [
        { path: 'home', meta: { title: 'Home' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: '/perfil', meta: { title: 'Perfil de Agremiado' }, name: 'profile', component: () => import("../views/account/profile.vue") },
        { path: '/votacion', meta: { title: 'Votacion' }, name: 'vote', component: () => import("../views/lawyers/vote/vote.vue") },
        { path: '/votacion/resultado', meta: { title: 'Resultados' }, name: 'results', component: () => import("../views/lawyers/vote/results.vue") },
        { path: '/solicitud', meta: { title: 'Solicitud 25%' }, name: 'application', component: () => import("../views/registryoffices/application/application.vue") }, 
        { path: '/mesa', meta: { title: 'Videos' }, name: 'trade', component: () => import("../views/registryoffices/trade/trade.vue") }, 
    ]
}
export const appRouters: Array<Router> = [{
    path: '/matricula',
    name: 'publicenroll',
    meta: {
        title: 'Matricula'
    },
    component: () => import('../views/public/enrollment/enrollment.vue'),
    children: []
},
{
    path: '/asistencia/:id',
    name: 'publicmark',
    meta: {
        title: 'Asistencia'
    },
    component: () => import('../views/public/attendance/mark.vue'),
    children: []
},
{
    path: '/habilitados',
    name: 'publicenabled',
    meta: {
        title: 'Habilitados'
    },
    component: () => import('../views/public/lawyer/enabled.vue'),
    children: [] 
},
{
    path: '/videoteca',
    name: 'publicvideoteca',
    meta: {
        title: 'Videoteca'
    },
    component: () => import('../views/public/videoteca/videoteca.vue'),
    children: [] 
}];

export const dbRouters = {


} 

export const routers = [
    loginRouter,
    ...appRouters,
    otherRouters
];
