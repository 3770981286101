import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/admin/user'
  
import student from './modules/students/student'
import address from './modules/students/address'
import documenttype from './modules/students/documenttype'
import group from './modules/students/group'
import gender from './modules/students/gender'
 
import attendance from './modules/courses/attendance'
import courseclass from './modules/courses/courseclass'
import lesson from './modules/courses/lesson'
import enrollment from './modules/courses/enrollment'
import course from './modules/courses/course'
import fee from './modules/courses/fee'

import lawyer from './modules/lawyers/lawyer'
import vote from './modules/lawyers/vote'
import payment from './modules/lawyers/payment'

import application from './modules/registryoffices/application'
import trade from './modules/registryoffices/trade'

import video from './modules/videos/video'

import country from './modules/preferences/country'
import department from './modules/preferences/department'
import currency from './modules/preferences/currency'
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'



const store = new Vuex.Store({
    state: {
        //
        //loading: boolean
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,

        student,
        address,
        documenttype,
        group,
        gender,

        attendance,
        courseclass,
        lesson,
        enrollment,
        course,
        fee,

        lawyer,
        vote,
        payment,

        application,
        trade,

        video,

        country,
        department,
        currency,
        configuration,
        generaltype,
        generaltypevalue,
    }
});

export default store;