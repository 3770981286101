import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Enrollment from '../../entities/courses/enrollment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface EnrollmentState extends ListState<Enrollment>{
    editModel: Enrollment;
    activeList: Array<Enrollment>;
    hisLoading: boolean;
    history: Array<any>;    
}
class EnrollmentModule extends ListModule<EnrollmentState, any, Enrollment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Enrollment>(),
        loading: false,
        hisLoading: false,
        editModel:new Enrollment(),
        activeList: new Array<Enrollment>(),
        history: new Array<any>(),
        path: 'enrollments'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const enrollmentModule=new EnrollmentModule();
export default enrollmentModule;
