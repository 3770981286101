import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Application from '../../entities/registryoffices/application'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ApplicationState extends ListState<Application>{
    editModel: Application;
    uploading: boolean;
}
class ApplicationModule extends ListModule<ApplicationState,any,Application>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Application>(),
        loading: false,
        uploading: false,
        editModel: new Application(),
        activeList: new Array<Application>(),
        path: 'lawyer/applications'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async uploadForm(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.post('/api/lawyer/applications/upload-form', payload.data).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            return response.data.result;
        },
        async update(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/lawyer/applications/upload-alert', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const applicationModule=new ApplicationModule();
export default applicationModule;
