
























import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import sidebarMenu from './components/sidebarMenu.vue';
import util from '../../lib/util';
import AppConst from '../../lib/appconst'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    components: { VuePerfectScrollbar, sidebarMenu },
    data: () => ({
        mini: false,
        scrollSettings: {
            maxScrollbarLength: 160
        }
    }),
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.drawer = val;
        },
        drawer: function (val) {
            let obj = (this as any);
            if (!val && this.$vuetify.breakpoint.xsOnly) {
                //this.$emit('hide-drawer');
                this.$store.commit('app/toggleShrink');
            }
        },
    }
})
export default class ShrinkableMenu extends Vue {
    name: string = 'shrinkableMenu';
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ required: true, type: Array }) menuList: Array<any>;
    @Prop({ type: Array }) openNames: Array<string>;
    @Prop({ type: Function }) beforePush: Function;
    @Prop({ validator: (val) => { return util.oneOf(val, ['dark', 'light']); } }) theme: string;
    drawer: boolean = false; 

    get bgColor() {
        return this.theme === 'dark' ? '#001529' : '#fff';
    }
    get loading() {
        return false;
    }
    get nroTabs() {
        return AppConst.setting.nroTabs;
    }
    @Emit('on-change')
    handleChange(name: string) {
        //let willpush = true;
        //if (this.beforePush !== undefined) {
        //    if (!this.beforePush(name)) {
        //        willpush = false;
        //    }
        //}
        //if (willpush) {
        //    this.$router.push({name:name})
        //}
    }

    created() {

    }
}
