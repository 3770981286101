import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Lawyer from '../../entities/lawyers/lawyer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Application from '@/store/entities/registryoffices/application';
import Trade from '@/store/entities/registryoffices/trade';

interface LawyerState extends ListState<Lawyer>{
    editModel:Lawyer;
    votes: Array<any>;
    application: Application;
    trade: Trade;
}
class LawyerModule extends ListModule<LawyerState,any,Lawyer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Lawyer>(),
        loading:false,
        editModel: new Lawyer(),
        activeList: new Array<Lawyer>(),
        application: new Application(),
        trade: new Trade(),
        votes: new Array<any>(),
        path: 'lawyer'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async updatePassword(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/lawyer/update-password', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async getVotes(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.get('/api/lawyer/votes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.votes = reponse.data.result;
        },
        async getApplication(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.get('/api/lawyer/application', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.application = reponse.data.result;
        },
        async getTrade(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.get('/api/lawyer/trade', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.trade = reponse.data.result;
        },
        async getByDocument(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/lawyer-document', { params: payload.data });
            context.state.loading = false;
            return reponse.data.result;
        },
        async download(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            payload.data.sale = payload.data.id;
            let reponse = await Ajax.get('/api/lawyer/lawyer-pdf', {
                responseType: 'blob',
                params: payload.data
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: LawyerState, model: Lawyer) {
            state.editModel = model; 

            if (state.editModel.address == null) {
                state.editModel.address = Object.assign({});
                state.editModel.address.country_id = 171;
            } else {
                state.editModel.address.ubigeo = {
                    department: state.editModel.address.department_id,
                    province: state.editModel.address.province_id,
                    district: state.editModel.address.district_id
                }
            }

            if (state.editModel.address_legal == null) {
                state.editModel.address_legal = Object.assign({});
                state.editModel.address_legal.country_id = 171;
            } else {
                state.editModel.address_legal.ubigeo = {
                    department: state.editModel.address_legal.department_id,
                    province: state.editModel.address_legal.province_id,
                    district: state.editModel.address_legal.district_id
                } 
            }

            if (state.editModel.address_work == null) {
                state.editModel.address_work = Object.assign({});
                state.editModel.address_work.country_id = 171;
            } else {
                state.editModel.address_work.ubigeo = {
                    department: state.editModel.address_work.department_id,
                    province: state.editModel.address_work.province_id,
                    district: state.editModel.address_work.district_id
                }
            }
        },
    });
}
const lawyerModule=new LawyerModule();
export default lawyerModule;
