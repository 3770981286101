import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Vote from '../../entities/lawyers/vote'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface VoteState extends ListState<Vote>{
    editModel:Vote;
    option: any;    
}
class VoteModule extends ListModule<VoteState,any,Vote>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Vote>(),
        loading:false,
        editModel: new Vote(),
        activeList: new Array<Vote>(),
        option: Object.assign({}),
        path: 'lawyer/votes'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getResults(context: ActionContext<VoteState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/lawyer/votes/results', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setOption(state: VoteState, model: any) {
            state.option = model;
        },
    });
}
const voteModule=new VoteModule();
export default voteModule;
