import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Configuration from '../../entities/preferences/configuration'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ConfigurationState extends ListState<Configuration>{
    editModel: Configuration;
}
class ConfigurationModule extends ListModule<ConfigurationState, any, Configuration>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Configuration>(),
        loading:false,
        editModel:new Configuration(),
        path: 'configurations'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const configurationModule=new ConfigurationModule();
export default configurationModule;