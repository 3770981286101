import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CourseClass from '../../entities/courses/courseclass'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface CourseClassState extends ListState<CourseClass>{
    editModel: CourseClass;
    viewModel: CourseClass;
    hisLoading: boolean;
    history: Array<any>;    
}
class CourseClassModule extends ListModule<CourseClassState, any, CourseClass>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CourseClass>(),
        activeList: new Array<CourseClass>(),
        loading: false,
        hisLoading: false,
        editModel: new CourseClass(),
        history: new Array<any>(),
        path: 'course-classes',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.get('/api/course-classes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<CourseClass>;

            context.state.totalCount = page.total;
            context.state.list = page.data;
            context.state.list.forEach((item: any) => {
                item.stateColor = Util.abp.list.getItem(item.state_id).extra;
            });
        },
        async getAvailable(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/course-classes-available', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const courseclassModule=new CourseClassModule();
export default courseclassModule;
