import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Video from '../../entities/videos/video'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface VideoState extends ListState<Video>{
    editModel:Video;
    
}
class VideoModule extends ListModule<VideoState,any,Video>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Video>(),
        loading:false,
        editModel: new Video(),
        activeList: new Array<Video>(),
        path: 'videos'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getPublicList(context: ActionContext<VideoState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/public-videos', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const videoModule=new VideoModule();
export default videoModule;
