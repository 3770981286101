import Vue from 'vue';
import App from './App.vue'; 
//import './registerServiceWorker' 
import {router} from './router/index';
import 'famfamfam-flags/dist/sprite/famfamfam-flags.css';
import './theme.less';
import Ajax from './lib/ajax';
import Util from './lib/util'; 
import vuetify from './lib/vuetify';
import VuetifyToast from 'vuetify-toast-snackbar'  
import VueSweetalert2 from 'vue-sweetalert2';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

Vue.use(VuetifyToast);
Vue.use(VueSweetalert2);
Vue.use(require('vue-shortkey'), { prevent: ['.excl-shortkey input', 'textarea'] });
Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    iconsGroup: 'md'
})

import store from './store/index';
Vue.config.productionTip = false;
import { appRouters, otherRouters } from './router/router';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'font-awesome/css/font-awesome.css'
const moment = require('moment') 
require('moment/locale/es')
Vue.use(require('vue-moment'), {moment})

Ajax.get('/api/lawyer/getConfigurations').then(data=>{
    Util.abp = Util.extend(true, Util.abp, data.data.result);
}).finally(function () {    

    new Vue({
        vuetify,
        render: h => h(App),
        router: router,
        store: store,
        data: {
            currentPageName: '',
            $confirm: (title: string, message: string, options?: any) => { }
        },
        async mounted() {
            this.currentPageName = this.$route.name as string;
	    
	        //if (!!this.$store.state.session.user && this.$store.state.session.application.features['SignalR']) {
            //    if (this.$store.state.session.application.features['SignalR.AspNetCore']) {
            //        SignalRAspNetCoreHelper.initSignalR();
            //    }
            //}

            if (!!Util.abp.session.userId) {
                await this.$store.dispatch({
                    type: 'session/init'
                })

                this.$store.commit('app/initCachepage');
            }
        },
        created() {
            let tagsList: Array<any> = [];
            appRouters.map((item) => {
                if (item.children != undefined) {
                    if (item.children.length <= 1) {
                        tagsList.push(item.children[0]);
                    } else {
                        tagsList.push(...item.children);
                    }
                }
            });

            this.$store.commit('app/setTagsList', tagsList);
        }
    }).$mount('#app')
})

